/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

// This is loaded through stimulus react_controller.js instead
// import "./react";

// Make accessible for Electron and Mobile adapters
window.Rails = Rails

require("@rails/activestorage").start()
import "@rails/actiontext"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Jumpstart Pro & other Functionality
import "./src/**/*"
require("local-time").start()

// Reporting
import "chartkick/chart.js"
// Chart.defaults.font.size = 22;
// Chart.defaults.backgroundColor = ["#008dd9", "#e63946", "#2fe1a6", "#71717a"]

// Posthog
import "./site/posthog"

// Start Rails UJS
Rails.start()

if ('serviceWorker' in navigator) navigator.serviceWorker.register("/sw.js")
